<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div :hidden="hidden">
        <b-card-code>
          <b-container fluid>
            <div>
              <div class="custom-search">
                <!-- advance search input -->
                <b-row>
                  <b-col>
                    <b-form-group>
                      <b-form-input
                        id="filter-input"
                        v-model="valueSearch"
                        type="search"
                        placeholder="Recherche"
                        @input="filterValue"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group>
                      <b-form-select
                        v-model="associationFilterValue"
                        :options="associations"
                        value-field="id"
                        text-field="name"
                        @input="filterValue"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            style="color: #b4b7bd; text-align: center"
                          >
                            -- Associations --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-input
                      v-model="soldeBenefices"
                      type="text"
                      disabled
                      placeholder="Solde"
                    >
                    </b-form-input>
                  </b-col>
                </b-row>
              </div>

              <b-table
                responsive
                :fields="fields"
                :items="inventaires"
                :filter="filter"
                :filter-function="filterFunction"
                size="lg"
              >
                <template #cell(periode)="data">
                  Du {{ toDate(data.item.from) }} Au {{ toDate(data.item.to) }}
                </template>

                <template #cell(benefice_net)="data">
                  {{ $thousandSeparator(data.item.benefice_net) }}
                </template>

                <template #cell(capital_precedent)="data">
                  {{ $thousandSeparator(data.item.capital_precedent) }}
                </template>

                <template #cell(nouveau_capital)="data">
                  {{ $thousandSeparator(data.item.nouveau_capital) }}
                </template>

                <!-- iconAction start -->
                <template #cell(actions)="data">
                  <b-button
                    size="sm"
                    variant="flat-primary"
                    @click="showItem(data.item)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <!-- <b-button
                    size="sm"
                    variant="flat-primary"
                    class="btn-icon"
                    @click="exporter(data.item)"
                  >
                    <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
                  </b-button> -->
                </template>
              </b-table>
            </div>
          </b-container>
        </b-card-code>
      </div>

      <div :hidden="hiddenEmpty" class="text-center text-secondary mt-2 mb-2">
        <empty-list></empty-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import {
  BIconCircleFill,
  BButton,
  BIconPlus,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BPagination,
  BBadge,
  BFormSelect,
  BFormCheckbox,
  BRow,
  BCol,
  BContainer,
  BTable,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    EmptyList,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BIconCircleFill,
    BRow,
    BCol,
    BBadge,
    BContainer,
    vSelect,
    BIconPlus,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BFormSelectOption,
    BIconFileEarmarkArrowDownFill,
  },
  data() {
    return {
      fields: [
        { label: "Date de l'inventaire", align: "start", key: "created_at" },
        { label: "Période", align: "start", key: "periode" },
        {
          label: "Association",
          align: "start",
          key: "association.name",
        },
        { label: "Ancien capital", key: "capital_precedent", sortable: false },
        { label: "Bénéfice", key: "benefice_net", sortable: false },
        { label: "Nouveau capital", key: "nouveau_capital", sortable: false },
        { label: "Actions", key: "actions", sortable: false },
      ],
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      minDate: "",
      overlay: false,

      search: "",
      loading: false,
      invent: false,
      inventaires: [],
      from: false,
      to: false,
      associationFilterValue: null,
      associations: [],

      lastInventaire: null, //new Date().toISOString().substr(0, 7),
      snackbar: false,

      inventairesData: {
        associationToFilter: null,
        revenantCaisse: 0,
        revenantFsr: 0,
      },
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      required,
      filter: "",
      valueSearch: "",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
    beneficeTotal() {
      let ben = this.inventairesData.benefice_net;
      return ben ? ben : 0;
    },

    inventairesDataFrom() {
      let date = new Date(this.inventairesData.LastInventaire);
      date.setDate(date.getDate() + 1);
      return (this.inventairesData.from = date); //date.toISOString().substr(0, 10))
    },

    beneficeTotalC() {
      return this.$thousandSeparator(this.inventairesData.benefice_net);
      // return (-1000)
    },

    options() {
      return {
        distractionFree: false,
        valueAsInteger: true,
        AllowNegative: false,
        autoDecimalMode: true,
        currency: null,
        precision: 0,
      };
    },

    disable() {
      if (this.inventairesData.to && this.inventairesData.associationToFilter) {
        return false;
      }
      return true;
    },

    soldeBenefices() {
      let ben = 0;
      let inventaires = this.inventaires;

      inventaires.forEach((element) => {
        if (element.benefice_net) {
          ben += this.$thousandFormater(element.benefice_net);
        }
      });

      return this.$thousandSeparator(ben);
    },
  },
  created() {
    this.GetInventairesData();
    this.getAssociationList();
  },
  methods: {
    getAssociationList() {
      this.$http
        .get("/associations")
        .then((res) => {
          this.associations = res.data.data.reverse();
        })
        .catch((e) => {
          console.log("erreur associesList:", e.response.data.message);
        });
    },
    filterValue(val) {
      this.filter = " ";
    },
    filterFunction(row) {
      if (
        this.associationFilterValue != null &&
        this.associationFilterValue != row.association_id
      ) {
        return false;
      } else if (this.valueSearch != "" && this.valueSearch != null) {
        if (
          row.association.name
            .toLowerCase()
            .indexOf(this.valueSearch.toLowerCase()) == -1 &&
          row.created_at
            .toString()
            .toLowerCase()
            .indexOf(this.valueSearch.toLowerCase()) == -1
        ) {
          return false;
        } else {
          return true;
        }
      } else return true;
    },

    async GetInventairesData() {
      this.$http.get("/inventaires").then((response) => {
        if (response.status) {
          this.hiddenLoader = true;
        }

        this.inventaires = response.data.data.reverse();

        if (this.inventaires.length) {
          this.hidden = false;
          this.hiddenEmpty = true;
        } else {
          this.hidden = true;
          this.hiddenEmpty = false;
        }
      });
    },

    toDate(dateStr) {
      return dateStr;
      //   if (dateStr) {

      //     dateStr = dateStr.replace(/\//g, "-");
      //     var parts = dateStr.split("-");
      //     return parts[2] + "-" + parts[1] + "-" + parts[0];
      //   }
    },

    nouveauInventaire() {
      this.inventairesData = {};
      // this.dialog = true
      this.$router.push({
        name: "inventaire-nouveau",
      });
    },

    revenantCaisse() {
      let num = this.inventairesData.part_du_association
        ? parse(this.inventairesData.part_du_association)
        : 0;
      this.inventairesData.part_du_principal = this.beneficeTotal - num;
      return this.$thousandSeparator(this.inventairesData.part_du_principal);
    },

    revenantFsr() {
      let num = this.inventairesData.part_du_principal
        ? parse(this.inventairesData.part_du_principal)
        : 0;
      this.inventairesData.part_du_association = this.beneficeTotal - num;
      // console.log(this)
      return this.$thousandSeparator(this.inventairesData.part_du_association);
      // }
    },

    showItem(item) {
      this.$router.push({
        name: "inventaire-show",
        params: { id: item.id },
      });
    },

    exporter(item) {
      window.open(item.file_link);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/pages/ui-feather.scss";

#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.archive {
  background-color: #ffb74d !important;
}

.vs__selected .vs__deselect {
  fill: seashell;
}

.v-select .vs__dropdown-menu {
  height: 100px;
}
</style>
